<template>
  <div>
    <b-row>
      <b-col cols="12" md="12">
        <b-card>
          <b-row class="mb-3">
            <b-col>
              <h5 class="mt-0 mb-1"> ตั้งค่าการวางเดิมพัน </h5>
            </b-col>
          </b-row>

          <form @submit.prevent="onSubmit">
            <b-form-group
              v-for="(userGameConfig, index) in (playerDetail.userGameConfigs || []).filter((gameConfig) => BetLimitConfig[gameConfig.game.path] !== undefined)"
              :key="index"
              label-cols-sm="12"
              label-cols-lg="3"
              :label="userGameConfig.game.name"
            >
              <multiselect
                v-if="form[userGameConfig.game.path]"
                v-model="form[userGameConfig.game.path].value"
                placeholder=""
                :multiple="true"
                :options="BetLimitConfig[userGameConfig.game.path].options"
                :max="BetLimitConfig[userGameConfig.game.path].max"
                label="text"
                track-by="value"
              >
                <template slot="option" slot-scope="props">
                  <div class="d-flex align-items-center" style="height: 25px">
                    <span class="mx-2">{{ props.option.text }}</span>
                  </div>
                </template>
              </multiselect>
            </b-form-group>

            <div class="text-right">
              <b-overlay
                :show="isLoading"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button type="submit" variant="primary" block>
                  {{ $t('buttons.save') }}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {BetLimit} from './bet-limit'

export default {
  page: {
    title: 'ตั้งค่าการวางเดิมพัน',
  },
  components: {},
  data() {
    return {
      isLoading: false,
      form: {},
    }
  },
  computed: {
    ...mapState({
      isFetchingPlayer: (state) => state.player.isFetchingPlayer,
      isFetchingPlayerAf: (state) => state.player.isFetchingPlayerAf,
      isFetchingPlayerCashback: (state) =>
        state.player.isFetchingPlayerCashback,
    }),
    ...mapGetters(['playerDetail', 'playerCashbackLogs', 'playerAfLogs']),
    playerId() {
      return this.$route.params.id
    },
    BetLimitConfig() {
      return BetLimit
    },
  },
  async created() {
    await this.fetchPlayer(this.playerId)
    if(this.playerDetail.userGameConfigs) {
      this.form = this
        .playerDetail
        .userGameConfigs
        .filter((gameConfig) => this.BetLimitConfig[gameConfig.game.path] !== undefined)
        .map((gameConfig) => ([
          gameConfig.game.path,
          {
            gameConfigId: gameConfig.id,
            value: gameConfig.userGameConfigs?.[0] ? this.mapValueToBetOption(gameConfig.userGameConfigs?.[0]?.betLimit, gameConfig.game.path) :  [],
          }
        ]))
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {})
    }
  },
  methods: {
    ...mapActions([
      'fetchPlayer',
      'deletePlayer',
      'fetchPlayerAfLogs',
      'fetchPlayerCashbackLogs',
      'setUserBetLimit',
    ]),
    mapValueToBetOption(value, gamePath){
      const valueArr = JSON.parse(value || '[]') || [];
      const betLimitOptions = this.BetLimitConfig[gamePath]?.options
      const ret =  valueArr.map((v) => {
        return betLimitOptions?.find((option) => v === option.value)
      })

    return ret?.filter((v) => !!v)

    },
    async onSubmit() {
      this.isLoading = true
      let payload = Object.values(this.form).map((value) => {
        return {
          gameConfigId: value.gameConfigId,
          betLimitId: value.value?.length ? JSON.stringify(value.value?.map((v) => v.value)) : ''
        }
      })
      await this.setUserBetLimit({ id: this.playerId, payload: payload})
      this.isLoading = false
    },
  },
}
</script>
